.flip-card {
  width: 280px;
  height: 140px;
  perspective: 500px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  box-sizing: border-box;
  left: 0;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(150deg);
}

.flip-card-front,
.flip-card-back {
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
  z-index: 1;
}

.lines-band {
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background: linear-gradient(to right, rgba(191, 191, 191, 1) 0%, rgba(242, 242, 242, 1) 50%, rgba(191, 191, 191, 1) 100%);
  display: flex;
  flex: 1;
  align-content: space-between;
  flex-wrap: wrap;
  opacity: 0.4;
}

.lines-band div {
  background: white;
  width: 100%;
  height: 3px;
}

.cod {
  background: white;
  display: flex;
}

.ripple {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -10px;
  top: -10px;
  transition: all 600ms ease;
}

.ripple.active {
  width: 670px;
  height: 670px;
  left: -350px;
  top: -350px;
}

.number-item:nth-child(4n):not(:last-child) {
  margin-right: 14px;
}
.crossLine {
  position: absolute;
  width: 2px;
  height: 200%;
  opacity: 0.8;
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(218, 92, 92, 0.8) 7%, rgba(255,255,255,0) 100%);
}

.crossLineX {
  transform: rotate(90deg);
}

.perspectiveBoxCompass {
  transform-style: preserve-3d;
  transform: perspective(7cm);
}